import React, { useState } from "react";
import moment from "moment";
import { LogsDetail } from './LogDetail';
import { AppPaper, StyledTitle, ListPage } from '@applications-terrains/birdz-react-library';
import { IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

type Log = {
  id: number;
  date: any;
  ip: string;
  message: string;
  type: number;
  type_label: string;
  user_agent: string;
  user__name: string;
};

const Logs = () => {
  const endpoint = "/api/administration/activity-logs/";
  const [logDetail, setLogDetail] = useState<any>();

  const listFields = [
    { name: "id", label: "#", options: { width: "30px" } },
    {
      name: "date",
      label: "Date",
      orderable: true,
      transform: (value: any) => {
        return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
      },
      options: { width: "150px" },
    },
    {
      name: "user__name",
      label: "Utilisateur",
      className: "text-center",
      options: { width: "170px" },
      orderable: true,
    },
    {
      name: "type_label",
      label: "Type",
      className: "text-center",
      options: { width: "170px" },
    },
    {
      name: "message",
      label: "Message",
      className: "text-center",
    },
  ];

  const searchFields = [
    {
      name: "user",
      label: "Utilisateur",
      options: {
        identifier: "id",
        source: "/api/administration/users/",
        label: "name",
        searchIsMulti: false,
      },
      className: "col-sm-6",
    },
    {
      name: "type",
      label: "Type",
      type: "select-multiple",
      options: {
        values: [
          { value: 1, label: "Connexion" },
          { value: 2, label: "INFO" },
          { value: 3, label: "WARNING" },
          { value: 4, label: "ERROR" },
        ],
      },
      className: "col-sm-6",
    },
    {
      name: "date_after",
      label: "Entre le ",
      type: "datepicker",
      className: "col-sm-6",
    },
    {
      name: "date_before",
      label: "et le",
      type: "datepicker",
      className: "col-sm-6",
    },
  ];

  const actions = [
    {
      name: "detail",
      render: (log: Log) => {
        return <IconButton onClick={() => {
          setLogDetail(log);
        }}><SearchIcon /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      <>
        <StyledTitle>Liste des logs</StyledTitle>
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
          exportButtons={[{ name: "Exporter en csv", fileName: 'test', type: "", icon: <>icc</>, requestEndpoint: '/api/cr-plus/config-updates/export/', requestMethod: 'POST', responseType: 'json' }]}
        />
      </>
      {logDetail && (
        <LogsDetail
          logItem={logDetail}
          onClose={() => {
            setLogDetail(null)
          }}
        />
      )}
    </AppPaper>
  );

}
export default Logs;