import Home from './components/Home';
import Login from './components/Login/Login';
import DashboardLayout from './components/DashboardLayout';
import Users from './components/Admin/Users/Users';
import Groups from './components/Admin/Groups/Groups';
import { Outlet } from 'react-router-dom';
import PrivateOutlet from './components/Common/Auth/PrivateOutlet';
import Sections from './components/Admin/Sections/Sections';
import Logs from './components/Admin/Logs/Logs';
import Messages from './components/Admin/Messages/Messages';
import { CallbackLogin } from './components/Common/Auth/CallbackLogin';
import NotFound from './components/NotFound';
import Companies from './components/Admin/Companies/Companies';
import Sessions from './components/Sessions/Sessions';
import Applications from './components/Applications/Applications';
import Licences from './components/Licences/Licences';
import LicenceDetail from './components/Licences/LicenceDetail';

export interface RouteObjectProps {
  path: string;
  element?: any;
  label?: string;
  permissions?: string[];
  children?: RouteObjectProps[];
}

const routes: RouteObjectProps[] = [
  {
    path: '/callback',
    element: <CallbackLogin />
  },
  {
    path: '/auth',
    element: <Login />,
  },
  {
    path: '/',
    element: <DashboardLayout component={<PrivateOutlet />} />,
    children: [
      {
        path: '',
        element: <Home />,
        label: 'Accueil'
      },
      {
        path: 'sessions',
        element: <Sessions />,
        label: 'Sessions'
      },
      {
        path: 'applications',
        children: [
          {
            path: '',
            element: <Applications action="list" />,
            label: 'Licences'
          },
          {
            path: 'add',
            element: <Applications action="add" />,
            label: 'Ajouter',
          },
          {
            path: 'edit/:id',
            element: <Applications action="edit" />,
            label: 'Éditer',
          }
        ]
      },
      {
        path: 'licences',
        children: [
          {
            path: '',
            element: <Licences />,
            label: 'Licences'
          },
          {
            path: 'add',
            element: <LicenceDetail />,
            label: 'Ajouter',
          },
          {
            path: 'edit/:id',
            element: <LicenceDetail />,
            label: 'Éditer',
          }
        ]
      },
      {
        path: 'administration',
        label: 'Administration',
        element: <Outlet />,
        children: [
          {
            path: 'users',
            children: [
              {
                path: '',
                element: <Users action='list' />,
                label: 'Utilisateurs',
              },
              {
                path: 'create',
                element: <Users action='add' />,
                label: 'Ajouter',
              },
              {
                path: 'edit/:id',
                element: <Users action='edit' />,
                label: 'Éditer',
              }
            ]
          },
          {
            path: 'groups',
            children: [
              {
                path: '',
                element: <Groups action="list" />,
                label: 'Groupes'
              },
              {
                path: 'add',
                element: <Groups action="add" />,
                label: 'Ajouter',
              },
              {
                path: 'edit/:id',
                element: <Groups action="edit" />,
                label: 'Éditer',
              }
            ]
          },
          {
            path: 'sections',
            label: 'Sections',
            children: [
              {
                path: 'create',
                element: <Sections action='add' />,
                label: 'Ajouter',
              },
              {
                path: 'edit/:id',
                element: <Sections action="edit" />,
                label: 'Éditer',
              },
              {
                path: '',
                element: <Sections action="list" />,
                label: 'Sections'
              },
            ]
          },
          {
            path: 'messages',
            children: [
              {
                path: '',
                element: <Messages action="list" />,
                label: 'Messages'
              },
              {
                path: 'add',
                element: <Messages action="add" />,
                label: 'Ajouter',
              },
              {
                path: 'edit/:id',
                element: <Messages action="edit" />,
                label: 'Éditer',
              }
            ]
          },
          {
            path: 'logs',
            element: <Logs />,
            label: 'Logs',
          },
          {
            path: 'companies',
            children: [
              {
                path: '',
                element: <Companies action="list" />,
                label: 'Sociétés'
              },
              {
                path: 'add',
                element: <Companies action="add" />,
                label: 'Ajouter',
              },
              {
                path: 'edit/:id',
                element: <Companies action="edit" />,
                label: 'Éditer',
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
]

export default routes;
