import React from "react";
import Item from "../../Common/Item/Item";
import moment from "moment";
import { Button, Grid, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { notificationService } from "../../Common/Layout/Notification";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";


const Companies = (params: any) => {
  const endpoint = "/api/administration/companies/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  const formFields = [
    {
      name: "name",
      type: "text",
      label: "Nom"
    },
    {
      name: "technical_code",
      type: "text",
      label: "Code technique"
    },
    {
      name: "comment",
      type: "textarea",
      label: "Commentaire"
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, name: "Actif" },
          { value: false, name: "Inactif" },
        ],
      },
      defaultValue: true,
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  const listFields = [
    { name: "name", label: "Société", orderable: true },
    {
      name: "technical_code",
      label: "Code technique",
      options: { width: "140px" },
      orderable: true,
    },
    { name: "comment", label: "Commentaire", orderable: true },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];
  const searchFields = [
    {
      name: "name",
      label: "Nom société",
      options: {
        identifier: "code",
        source: endpoint,
        label: "code",
        searchIsMulti: false,
      },
    }
  ];
  const actions = [
    {
      name: "edit",
      render: (row: any) => {
        console.log({ row })
        return <Link to={`/administration/companies/edit/${row.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
      }
    },
    {
      name: "delete-company",
      render: (row: any) => {
        return <IconButton size="small" onClick={() => {
          confirmDialog({
            title: "Supprimer la société",
            content: "Êtes-vous sûr de vouloir supprimer cette société?",
            onValidate: () => {
              axios.delete(`${endpoint}${row.id}/`).then(
                (response: any) => {
                  notificationService.show({
                    title: "Suppression effectuée avec succès",
                    message: "La société a été supprimée avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                (error) => {
                  notificationService.show({
                    title: "Erreur",
                    message: "Une erreur est survenue lors de la suppression",
                    type: "danger",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><DeleteIcon /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid
            justifyContent="space-between"
            container
          >
            <Grid item>
              <StyledTitle>Liste des sociétés</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/administration/companies/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} />  Ajouter une société
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter une société"
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier la société"
        />
      )}
      <BirdzDialog options={dialogOptions} />
    </AppPaper>
  );
}

export default Companies;
