import axios from "axios";
import _ from "lodash";

type Data = {
  [key: string]: any;
};

class DataService {
  public dataToLoad: { name: string; endpoint: string; permissions?: string[] }[] = [
    {
      name: "groups",
      endpoint: "/api/administration/groups/?size=500",
    },
    {
      name: "sections",
      endpoint: "/api/administration/sections/?size=500",
    },
    {
      name: "messageTypes",
      endpoint: "/api/administration/messages-type/"
    }
  ];

  public data: Data = {};

  public async loadData(userPermissions: string[] | null = null) {
    let allPromises: any = [];

    this.dataToLoad.forEach((data) => {
      if (!data.permissions || (data.permissions && userPermissions && _.difference(data.permissions, userPermissions).length === 0)) {
        allPromises.push(axios.get(data.endpoint));
      }
    });

    return Promise.all(allPromises).then(
      (allData) => {
        allData.forEach((response: any, dataIndex) => {
          this.data[this.dataToLoad[dataIndex].name] =
            response.data.results || response.data;
        });
      },
      (error) => {
        console.error(
          "L'endpoint " + error.response.config.url + " n'est pas joignable"
        );
      }
    );
  }

  public async get(dataName: string, endpoint: string = '') {
    if (!this.data || !this.data[dataName]) {
      endpoint = endpoint || dataName;
      const response = await axios.get('/api/' + endpoint + '/');
      this.data[dataName] = response?.data?.results || [];
    }
    return this.data[dataName]
  }

  public getData(dataName: string, filterCriteria: any = null) {
    let data = (this.data && this.data[dataName]) || [];
    if (filterCriteria) {
      let filterName = Object.keys(filterCriteria)[0];
      let filterValue = filterCriteria[filterName];
      data = data.filter((item: any) => item[filterName] === filterValue);
    }

    return data;
  }

  public getDeviceType(deviceTypeId: number) {
    let protocol = this.getData("deviceTypes").find(
      (deviceType: any) => deviceTypeId && deviceType.id === deviceTypeId
    );

    return protocol?.name || "";
  }

  public getProtocol(protocolId: number) {
    let protocol = this.getData("protocols").find(
      (protocol: any) => protocolId && protocol.technical_code === protocolId
    );

    return protocol?.name || "";
  }

  public dataStoreInDataService(dataName: string) {
    return this.dataToLoad.find((data) => data.name === dataName);
  }

  public refreshData(dataName: string) {
    return new Promise((resolve, reject) => {
      let dataToLoadConfig = this.dataStoreInDataService(dataName);
      if (dataToLoadConfig) {
        axios.get(dataToLoadConfig.endpoint).then(
          (response) => {
            this.data[dataName] = response.data.results || response.data;
            resolve(true);
          },
          () => {
            reject();
          }
        );
      } else {
        reject();
      }
    });
  }
}

let dataService = new DataService();
export default dataService;
