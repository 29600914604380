import React from "react";
import { AppPaper, StyledTitle, ListPage } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

export type Licence = {
    app: number,
    app__name: string,
    app__version: string,
    created_at: number,
    id: number,
    last_connection: string,
    number: number,
    sessions_count: number,
    updated_at: number,
    user: number
}

const Licences = () => {
    const endpoint = "/api/administration/applications/";

    const listFields = [
        { name: "name", label: "Application", orderable: true },
        {
            name: "version",
            label: "Version",
            options: { width: "140px" },
            orderable: true,
        },
        { name: "licences_count", label: "Nb de licences", orderable: true },

    ];
    const searchFields = [
        {
            name: "app",
            label: "Par application",
            options: {
                identifier: "id",
                source: '/api/administration/applications/',
                label: "name",
                searchIsMulti: false,
            },
        },
        {
            name: "user",
            label: "Par utilisateur",
            options: {
                identifier: "id",
                source: '/api/administration/users/',
                label: "name",
                searchIsMulti: false,
            },
        }
    ];
    let actions = [
        {
            name: "edit",
            render: (application: Licence) => {
                return <Link to={`/licences/edit/${application.id}`}>
                    <EditIcon />
                </Link>
            }
        }
    ];

    return (
        <AppPaper>
            <>
                <StyledTitle>Liste des licences</StyledTitle>

                <ListPage
                    endpoint={endpoint}
                    fields={listFields}
                    actions={actions}
                    searchFields={searchFields}
                />
            </>
        </AppPaper>
    );
}

export default Licences;
