
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

type DetailField = {
    label: string;
    name: keyof Log;
};

type LogsDetailProps = {
    logItem: Log;
    onClose(): void;
};

type Log = {
    id: number;
    date: any;
    ip: string;
    message: string;
    type: number;
    type_label: string;
    user_agent: string;
    user__name: string;
};


export const LogsDetail = ({ logItem, onClose }: LogsDetailProps) => {
    const [show, setShow] = useState(true);

    const detailFields: DetailField[] = [
        { label: "Id", name: "id" },
        { label: "Date", name: "date" },
        { label: "Utilisateur", name: "user__name" },
        { label: "Type d'action", name: "type_label" },
        { label: "Message", name: "message" },
        { label: "Adresse IP", name: "ip" },
        { label: "Informations complémentaires", name: "user_agent" },
    ];

    return (
        <>
            <Modal
                show={show}
                animation={false}
                onHide={() => {
                    setShow(false);
                    onClose();
                }}
                dialogClassName="modal-80w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Détail log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailFields.map((field: DetailField) => {
                        return (
                            <Row className="pb-4" key={field.name}>
                                <Col sm="4">{field.label}</Col>
                                <Col sm="8">{logItem[field.name]}</Col>
                            </Row>
                        );
                    })}
                </Modal.Body>
            </Modal>
        </>
    );
};
