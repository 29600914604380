import React, { useState } from "react";
import moment from "moment";
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { IconButton } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import axios from "axios";
import { notificationService } from "../Common/Layout/Notification";

const Sessions = (params: any) => {
    const endpoint = "/api/administration/sessions/";
    const [listVisibility, setListVisibility] = useState<boolean>(true);
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    const refreshListing = () => {
        setListVisibility(false);
        setTimeout(() => {
            setListVisibility(true);
        });
    };

    const listFields = [
        { name: "licence__app", label: "Application", orderable: true },
        {
            name: "licence__user__email",
            label: "Utilisateur",
            orderable: true,
        },
        {
            name: "last_keep_alive",
            label: "Dernière connexion",
            options: { width: "220px" },
            orderable: true,
            transform: (value: any) => {
                return (
                    (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
        }
    ];
    const searchFields = [
        {
            name: "licence__app",
            label: "Par application",
            options: {
                identifier: "id",
                source: '/api/administration/applications/',
                label: "name",
                searchIsMulti: false,
            },
        },
        {
            name: "licence__user",
            label: "Par utilisateur",
            options: {
                identifier: "id",
                source: '/api/administration/users/',
                label: "name",
                searchIsMulti: false,
            },
        }
    ];
    const actions = [
        {
            name: "session-close",
            render: (session: any) => {
                return <IconButton size="small" onClick={() => {
                    confirmDialog({
                        title: "Clôture session",
                        content: "Souhaitez-vous bien clôre cette session??",
                        onValidate: () => {
                            axios.post(`/api/administration/sessions/${session.id}/close/`).then((response) => {
                                notificationService.show({
                                    type: 'success',
                                    title: 'Session close',
                                    message: 'La session de l\'utilisateur a été close'
                                });
                                refreshListing();
                            })
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}><LogoutIcon /></IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            <>
                <StyledTitle>Liste des sessions</StyledTitle>
                {listVisibility &&
                    <ListPage
                        filters="&state=running"
                        endpoint={endpoint}
                        fields={listFields}
                        actions={actions}
                        searchFields={searchFields}
                    />
                }
                <BirdzDialog options={dialogOptions} />
            </>
        </AppPaper>
    );
}

export default Sessions;
