import React, { useEffect, useState } from "react";
import axios from "axios";
import { Trans } from "react-i18next";
import { Container } from "@mui/material";
import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library';

export const CallbackLogin = () => {
    const [error, setError] = useState<string>();
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("code")) {
            let code = urlParams.get("code");
            axios
                .post("/api/auth/sso/", { code })
                .then((response) => {
                    window.location.search = "";
                    window.location.pathname = "/";
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        setError('Votre compte n\'est pas autorisé à accèder à l\'application');
                    } else {
                        setError(error.message);
                    }

                    setTimeout(() => {
                        window.location.search = "";
                        window.location.pathname = "/";
                    }, 3000)
                })
                .finally(() => {
                    setIsReady(true)
                });
        }
    }, [])

    return (
        <>{isReady && <Container sx={{ mt: 2 }} >
            <AppPaper>
                <StyledTitle>{error ? 'Erreur' : 'Succès'}</StyledTitle>

                <p>{error && <Trans>{error}</Trans>}</p>
                <p>Redirection en cours...</p>
            </AppPaper>
        </Container >}</>
    );
}