import React from "react";
import { Link } from "react-router-dom";
import Item from "../../Common/Item/Item";
import { Field } from "../../Common/Config/FieldsInterface";
import moment from "moment";
import dataService from "../../Common/Services/dataService";
import * as Yup from "yup";
import { notificationService } from "../../Common/Layout/Notification";
import axios from "axios";
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Users = (params: any) => {
  const endpoint = "/api/administration/users/"
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  const transformItemOnSave = (user: any) => {
    if (user?.subcontractor) {
      user.subcontractor = user.subcontractor?.id;
    }
    if (!user.password) {
      delete user.password;
    }
    return user;
  }

  const formFields: Field[] = [
    {
      name: "name",
      type: "text",
      label: "Nom",
      validation: Yup.string().required("Vous devez renseigner le nom"),
    },
    {
      name: "email",
      type: "text",
      label: "Email",
      validation: Yup.string()
        .email("Vous devez renseigner un e-mail valide")
        .required("L'email ne peut être vide"),
    },
    {
      name: "password",
      type: "password",
      label: "Mot de passe",
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, name: "Actif" },
          { value: false, name: "Inactif" },
        ],
      },
      defaultValue: true,
    },
    { name: "avatar", type: "image", label: "Photo" },

    {
      name: "groups",
      type: "select-multiple",
      label: "Groupes rattachés",
      options: {
        values: dataService.getData("groups", { is_active: true }),
      },
    },
    {
      name: "last_login",
      type: "readonly",
      label: "Dernière connexion",
      transform: (value: any) => {
        let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  const listFields = [
    {
      name: "name",
      label: "Nom",
      orderable: true,
    },
    {
      name: "groups",
      label: "Groupes",
      orderable: true,
      transform: (values: any) => {
        let dataServiceGroups = dataService.getData("groups");
        let groups = values.map((value: number) => {
          let group = dataServiceGroups.find(
            (dataServiceGroup: any) => dataServiceGroup.id === value
          );
          return group?.name || value;
        });
        return groups.join(", ");
      },
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      orderable: true,
      options: { width: "130px" },
    },
    {
      name: "last_login",
      label: "Dernière connexion",
      transform: (value: any) => {
        return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
      },
      orderable: true,
      options: { width: "230px" },
    },
  ];

  const searchFields = [
    {
      name: "id",
      label: "Nom",
      options: {
        identifier: "id",
        source: "/api/administration/users/",
        label: "name",
        searchIsMulti: false,
      },
    },

    {
      name: "groups",
      label: "Groupe",
      options: {
        identifier: "id",
        dataKey: "groups",
        source: "/api/administration/groups/",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];


  const actions = [
    {
      name: "edit",
      render: (row: any) => {
        console.log({ row })
        return <Link to={`/administration/users/edit/${row.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
      }
    },
    {
      name: "delete-user",
      render: (row: any) => {
        return <IconButton size="small" onClick={() => {
          confirmDialog({
            title: "Supprimer l'utilisateur",
            content: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
            onValidate: () => {
              axios.delete(`${endpoint}${row.id}/`).then(
                (response: any) => {
                  notificationService.show({
                    title: "Suppression effectuée avec succès",
                    message: "L'utilisateur a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                (error) => {
                  notificationService.show({
                    title: "Erreur",
                    message: "Une erreur est survenue lors de la suppression",
                    type: "danger",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><DeleteIcon /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid
            justifyContent="space-between"
            container
          >
            <Grid item>
              <StyledTitle>Liste des utilisateurs</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/administration/users/create">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un utilisateur
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un utilisateur"
          transformItemOnSave={transformItemOnSave}
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier l'utilisateur"
          transformItemOnSave={transformItemOnSave}
        />
      )}
      <BirdzDialog options={dialogOptions} />
    </AppPaper>
  );

}

export default Users;




