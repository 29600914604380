import React from "react";
import { Alert } from "@mui/material";

const NotFound = () => {
  return (
    <Alert severity="info">
      Page introuvable
    </Alert>
  );
};
export default NotFound;
