import React, { useEffect, useState } from "react";
import axios from "axios";
import ItemForm from "./ItemForm";
import { useNavigate, useParams } from "react-router-dom";
import * as Cookies from "es-cookie";
import { Field } from "../Config/FieldsInterface";
import { notificationService } from "../Layout/Notification";
import dataService from "../Services/dataService";

interface Props {
  action: string;
  endpoint: string;
  fields: Field[];
  name?: string;
  id?: string | number;
  transformItemOnSave?: any;
  transformItemOnLoad?: any;
}

type ParamTypes = {
  id: string;
}

const Item = ({
  action,
  endpoint,
  fields,
  name,
  transformItemOnLoad,
  transformItemOnSave
}: Props) => {
  const [item, setItem] = useState<any>();

  const navigate = useNavigate();
  const { id } = useParams<ParamTypes>();
  let submitInProgress = false;

  useEffect(() => {
    const getDefaultValues = () => {
      let item: any = {};
      fields?.forEach((field: Field) => {
        switch (field.type) {
          case "text":
          case "file":
          case "password":
          case "textarea":
          case "url":
          case "richtext":
          case "number":
            item[field.name] = field.defaultValue || "";
            break;
          case "checkbox":
            item[field.name] = field.defaultValue || false;
            break;
          case "select":
            item[field.name] = null;
            break;
          case "select-multiple":
          case "autocomplete":
            item[field.name] = [];
            break;
          case "readonly":
            break;
          default:
            item[field.name] = field.defaultValue || null;
            break;
        }
      });
      return item;
    };

    let defaultItem = getDefaultValues();

    const load = async () => {
      return new Promise((resolve, reject) => {
        if (id) {
          axios.get(endpoint + id + "/").then(async (response) => {
            let data = response.data;
            data = Object.assign(defaultItem, data);
            if (typeof transformItemOnLoad === "function") {
              data = await transformItemOnLoad(data);
              resolve(data);
            } else {
              resolve(data);
            }
          });
        } else {
          resolve(defaultItem);
        }
      });
    };

    load().then((data) => {
      setItem(data);
    });
  }, [id, endpoint, fields, transformItemOnLoad]);

  const submitForm = async (item: any) => {
    if (submitInProgress) {
      return;
    }

    let itemToSubmit = Object.assign({}, item);

    if (typeof transformItemOnSave === "function") {
      itemToSubmit = await transformItemOnSave(itemToSubmit);
    }

    let editMode = id ? true : false;
    let url = endpoint;
    if (editMode) {
      url = url + id + "/";
    }
    let headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    let csrfToken = Cookies.get("csrftoken");
    if (csrfToken) {
      headers["X-CSRFToken"] = csrfToken;
    }

    submitInProgress = true;
    axios({
      url,
      method: editMode ? "PUT" : "POST",
      headers,
      data: itemToSubmit,
    })
      .then(
        (response) => {
          notificationService.show({
            title: "Succès",
            message: editMode
              ? "Enregistrement modifié avec succès"
              : "Enregistrement créé avec succès",
            type: "success",
          });

          // if data is store in dataservice, we refresh with the created/updated data
          let dataServiceData = dataService.dataToLoad.find(
            (dataConfig) => dataConfig.endpoint === endpoint
          );
          if (dataServiceData) {
            dataService.refreshData(dataServiceData.name).then(() => {
              setTimeout(() => {
                navigate(-1)
              }, 1000);
            });
          } else {
            setTimeout(() => {
              navigate(-1)
            }, 2000);
          }
        },
        (error) => {
          let statusCode = error.response.status;
          if (statusCode.toString().substring(0, 2) === "20") {
          } else if (statusCode.toString().substring(0, 1) === "5") {
            notificationService.show({
              title: "Erreur",
              message:
                "Une erreur serveur est survenue, merci de réessayer ultérieurement",
              type: "danger",
            });
          } else {
            let errorBody = error.response.data;
            let content;
            if (
              errorBody &&
              typeof errorBody === "object" &&
              Object.keys(errorBody).length > 0
            ) {
              content =
                "Une erreur est survenue, merci de corriger les champs suivants:<br /><ul>";
              for (let i in errorBody) {
                content +=
                  "<li>Champ `" +
                  i +
                  "` : " +
                  errorBody[i].join(", ") +
                  "</li>";
              }
            } else {
              content =
                "Une erreur est survenue lors de l'appel au serveur, merci de réessayer plus tard";
            }

            content += "</ul>";
            notificationService.show({
              title: "Erreur",
              message: content,
              type: "danger",
            });
          }
        }
      )
      .finally(() => {
        submitInProgress = false;
      });
  };

  return (
    <div className="block">
      {name && <h2>{name}</h2>}
      {(action === "add" && item) ||
        (action === "edit" &&
          item &&
          (item.id || item.sysid)) ? (
        fields !== undefined ? (
          <ItemForm
            fields={fields}
            item={item}
            submitForm={(item: any) => submitForm(item)}
          />
        ) : null
      ) : null}
    </div>
  );
};

export default Item;
