import React from "react";
import { auth } from "../Common/Auth/Auth";
import { useNavigate } from "react-router-dom";
import { Login as LoginForm } from '@applications-terrains/birdz-react-library';
import "./Login.scss";
import { Box } from "@mui/material";

const Login = () => {
    const navigate = useNavigate();
    return <Box className="loginForm">
        <LoginForm
            onSubmit={(credentials: any) => {
                return new Promise((resolve, reject) => {
                    auth.login(credentials.email, credentials.password).then(
                        () => {
                            resolve({});
                            navigate('/');
                        },
                        (error: any) => {
                            reject(typeof error === 'string' ? error : 'Une erreur serveur est survenue');
                        }
                    );
                })
            }}
            onSSOButtonClick={auth.login_sso}
            logo={<img src={process.env.PUBLIC_URL + "/licence-logo-gris.png"} alt="B-Licence" style={{ width: '100%', marginTop: '35px' }} />}
        />
    </Box>
}

export default Login;