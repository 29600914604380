import axios from "axios";
import _ from "lodash";

interface User {
    name: string;
    email: string;
    previous_login: string;
    photo: string;
    permissions: string[];
    subcontractor?: number;
}

export class Auth {
    public initialized = false;
    public isAuthenticated = false;
    public token: string | null = null;
    public user: User | null = null;

    public async getUserInfo() {
        await axios.get("/api/auth/user-info/").then(
            (response) => {
                this.user = response.data;
                this.isAuthenticated = true;
                this.initialized = true;
            },
            () => {
                this.initialized = true;
            }
        );
    }

    public login = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: "/api/auth/password/",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                data: { email, password },
            }).then(
                (response) => {
                    let data = response.data;
                    this.user = data;
                    this.isAuthenticated = true;
                    resolve({});
                },
                (error) => {
                    reject(error.response.data);
                }
            );
        });
    };
    public login_sso() {
        axios.get("/api/auth/get-auth-url/").then((response) => {
            let data = response.data;
            window.location = _.get(data, "auth_url");
        });
    }

    public logout = () => {
        axios.post("/api/auth/logout/").then((response) => {
            this.isAuthenticated = false;
            this.user = null;
            window.location.pathname = "/";
        });
    };

    public canAccess = (section: string) => {
        return this.user?.permissions?.includes(section);
    };
}

export let auth = new Auth();
