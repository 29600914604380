import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Navbar } from "react-bootstrap";
import { auth } from "./Common/Auth/Auth";
import "./DashboardLayout.scss";
import Breadcrumbs from "./Common/Layout/Breadcrumbs";
import { notificationService, Notification } from "./Common/Layout/Notification";
import moment from "moment";
import routes from "../routes";

const DashboardLayout = ({ component, ...props }: any) => {
  const { staticContext, ...rest } = props;
  return (
    <div className="wrapper">
      <Sidebar {...rest} />

      <Notification
        ref={(component: any) => {
          notificationService.instance = component;
        }}
      />

      <div className="main-panel">
        <Navbar bg="light" expand="lg">
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>&nbsp;
              {auth.user?.photo && (
                <div
                  className="avatar"
                  style={{
                    backgroundImage: 'url("' + auth.user.photo + '"',
                  }}
                />
              )}
              {auth.user ? auth.user.name : ""}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>

        {auth.user?.previous_login && (
          <span className="last-login">
            Dernière connexion le {moment(auth.user.previous_login).format("DD/MM/YYYY à HH:mm:ss")}
          </span>
        )}

        <Breadcrumbs routes={routes} />

        <div className="page-content">
          {component}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;