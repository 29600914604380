import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import Item from "../../Common/Item/Item";
import moment from "moment";
import dataService from "../../Common/Services/dataService";
import * as Yup from "yup";
import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Button, Grid, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { notificationService } from "../../Common/Layout/Notification";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

const Groups = (params: any) => {
    const endpoint = "/api/administration/groups/";
    const allSections = dataService.getData("sections", { is_active: true }).map((section: any) => {
        return { name: section.code, id: section.id };
    });
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    const formFields = [
        {
            name: "name",
            type: "text",
            label: "Nom",
            validation: Yup.string().required(
                "Vous devez renseigner le nom du groupe"
            ),
        },
        {
            name: "is_active",
            type: "radio",
            label: "Etat",
            options: {
                values: [
                    { value: true, name: "Actif" },
                    { value: false, name: "Inactif" },
                ],
            },
            defaultValue: true,
        },
        {
            name: "sections",
            type: "select-multiple",
            label: "Sections",
            options: {
                values: allSections
            },

            validation: Yup.array().required(
                "Vous devez choisir au moins une section"
            ),
        },
        {
            name: "updated_at",
            type: "readonly",
            label: "Dernière modification",
            transform: (value: any) => {
                let date =
                    value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
                return date || "";
            },
        },
    ];
    const listFields = [
        { name: "name", label: "Name", orderable: true },
        {
            name: "is_active",
            label: "Actif",
            className: "text-center",
            options: { width: "100px" },
            orderable: true,
        },
        {
            name: "users_list",
            label: "Liste des utilisateurs",
            className: "text-center",
            transform: (value: any, item: any) => {
                return (
                    <Link to={"/administration/users/?groups=" + item.id}>
                        <FontAwesomeIcon icon={faUsers} />
                    </Link>
                );
            },
            options: { width: "170px" },
        },
        {
            name: "sections_list",
            label: "Liste des sections",
            className: "text-center",
            transform: (value: any, item: any) => {
                return (
                    <Link to={"/administration/sections/?group=" + item.id}>
                        <FontAwesomeIcon icon={faCog} />
                    </Link>
                );
            },
            options: { width: "150px" },
        },
        {
            name: "updated_at",
            label: "Date de modification",
            options: { width: "215px" },
            orderable: true,
            transform: (value: any) => {
                return (
                    (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
        },
    ];
    const actions = [
        {
            name: "edit",
            render: (group: any) => {
                return <Link to={`/administration/groups/edit/${group.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
            }
        },
        {
            name: "delete-group",
            render: (row: any) => {
                return <IconButton size="small" onClick={() => {
                    confirmDialog({
                        title: "Supprimer le groupe",
                        content: "Êtes-vous sûr de vouloir supprimer ce groupe?",
                        onValidate: () => {
                            axios.delete(`${endpoint}${row.id}/`).then(
                                (response: any) => {
                                    notificationService.show({
                                        title: "Suppression effectuée avec succès",
                                        message: "Le groupe a été supprimé avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                (error) => {
                                    notificationService.show({
                                        title: "Erreur",
                                        message: "Une erreur est survenue lors de la suppression",
                                        type: "danger",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}><DeleteIcon /></IconButton>
            }
        }
    ];

    const searchFields = [
        {
            name: "name",
            label: "Nom groupe",
            options: {
                identifier: "name",
                dataKey: "groups",
                source: endpoint,
                label: "name",
                searchIsMulti: false,
            },
        },
    ];

    return (
        <AppPaper>
            {params.action === "list" && (
                <>
                    <Grid
                        justifyContent="space-between"
                        container
                    >
                        <Grid item>
                            <StyledTitle>Liste des groupes</StyledTitle>
                        </Grid>
                        <Grid item>
                            <Link to="/administration/groups/add">
                                <Button variant="contained" sx={{ mt: 3 }}>
                                    <AddCircleIcon sx={{ mr: 1 }} />  Ajouter un groupe
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <ListPage
                        endpoint={endpoint}
                        fields={listFields}
                        actions={actions}
                        searchFields={searchFields}
                    />
                </>
            )
            }
            {
                params.action === "add" && (
                    <Item
                        action="add"
                        endpoint={endpoint}
                        fields={formFields}
                        name="Ajouter un groupe"
                    />
                )
            }
            {
                params.action === "edit" && (
                    <Item
                        action="edit"
                        endpoint={endpoint}
                        fields={formFields}
                        name="Modifier le groupe"
                    />
                )
            }
            <BirdzDialog options={dialogOptions} />
        </AppPaper >
    );
}


export default Groups;
