import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AutocompleteField from "@applications-terrains/birdz-react-library";
import Select from "react-select";
import { Field } from "../Config/FieldsInterface";
import { faEdit, faPlus, faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { auth } from "../Auth/Auth";
//import { TimeWindows } from "../../Models/TimeWindows";
import Creatable from "react-select/creatable";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

type AutocompleteItem = {
  label: string;
  value: string | number;
};

interface Props {
  submitForm: any;
  item: any;
  fields: Array<Field>;
}

const ItemForm = ({ submitForm, item, fields }: Props) => {
  const [validationSchema, setValidationSchema] = useState<any>();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let editorConfig: any = {
    readonly: false
  }
  let formik: any = {};

  useEffect(() => {
    let newValidationSchema: any = {};
    fields
      .filter((field: Field) => {
        return typeof field.validation !== "undefined";
      })
      .map((field: Field) => {
        return { name: field.name, validation: field.validation };
      })
      .forEach((item: any) => (newValidationSchema[item.name] = item.validation));

    setValidationSchema(newValidationSchema);
  }, [fields]);

  const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleFile = async (
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile = e.target.files?.length ? e.target.files[0] : null;
    if (selectedFile) {
      let file = await toBase64(selectedFile);
      updateItem(fieldName, file);
    }
  }

  const deleteFile = (fieldName: string) => {
    updateItem(fieldName, "");
  }

  const handleRadio = (
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let field = getFieldByName(fieldName);
    let chosenOption;
    if (field.options?.values) {
      chosenOption = field.options.values.find((option: any) => {
        return option.name === e.target.value;
      });
    }
    if (chosenOption) {
      updateItem(fieldName, chosenOption.value);
    }
  }

  const getFieldByName = (fieldName: string) => {
    return fields.filter(
      (field: Field) => field.name === fieldName
    )[0];
  }

  const getIdentifierField = (fieldName: string) => {
    let field = getFieldByName(fieldName);
    return field.options?.identifier || "id";
  }

  const getLabelField = (fieldName: string) => {
    let field = getFieldByName(fieldName);
    return field.options?.label || "name";
  }

  const handleAutocompleteSelect = (fieldName: string, valueSelect: any) => {
    let identifier = getIdentifierField(fieldName);
    let label = getLabelField(fieldName);

    let itemValue: any;

    if (valueSelect) {
      if (typeof valueSelect === "object" && valueSelect instanceof Array) {
        itemValue = [];
        valueSelect.forEach((value: AutocompleteItem) => {
          itemValue.push({
            [identifier]: value.value,
            [label]: value.label,
          });
        });
      } else {
        itemValue = {
          [identifier]: valueSelect.value,
          [label]: valueSelect.label,
        };
      }
    }
    updateItem(fieldName, itemValue);
  }

  const handleSelect = (
    fieldName: string,
    valueSelect: AutocompleteItem | AutocompleteItem[]
  ) => {
    let field = getFieldByName(fieldName);
    let itemValue: any;

    if (!field.options?.searchIsMulti && !Array.isArray(valueSelect)) {
      itemValue = valueSelect?.value || [];
    } else {
      itemValue = [];
      if (valueSelect && Array.isArray(valueSelect)) {
        valueSelect.forEach((value: AutocompleteItem) => {
          itemValue.push(value.value);
        });
      }
    }
    updateItem(fieldName, itemValue);
  }

  const updateItem = (fieldName: string, fieldValue: any) => {
    formik.setFieldValue(fieldName, fieldValue);
    let field = getFieldByName(fieldName);

    if (typeof field.onUpdate === "function") {
      field.onUpdate(fieldValue, formik.values, (item: any) => {
        for (let itemKey in item) {
          formik.setFieldValue(itemKey, item[itemKey]);
        }
      });
    }
  }

  const checkPermission = (field: Field) => {
    if (field?.permissions) {
      if (field.permissions instanceof Array) {
        let canAccess = true;
        field.permissions.forEach((permission: string) => {
          if (!auth.canAccess(permission)) {
            canAccess = false;
          }
        });
        return canAccess;
      } else {
        return auth.canAccess(field.permissions);
      }
    } else {
      return true;
    }
  }

  const renderFormField = (field: Field) => {
    let identifierField = getIdentifierField(field.name);
    let labelField = getLabelField(field.name);

    let value = formik.values[field.name];
    if (typeof field.transform === "function") {
      value = field.transform(value);
    }

    if (!checkPermission(field)) {
      return <React.Fragment key={field.name}></React.Fragment>;
    }

    return (field["type"] === "readonly" && value) ||
      field["type"] !== "readonly" ? (
      <div key={field.name} className={field?.className || "col-sm-12"}>
        <Form.Group className="mb-3" as={Row} controlId={"formControl-" + field.name}>
          <Form.Label column sm={field?.labelSize || "2"}>
            {field.label}
          </Form.Label>
          <Col sm={field?.fieldSize || "10"}>
            {(() => {
              switch (field["type"]) {
                case "text":
                case "password":
                case "url":
                case "number":
                  return (
                    <BasicField
                      name={field.name}
                      type={field.type}
                      value={value}
                      onChange={(e: any) =>
                        updateItem(field.name, e.target.value)
                      }
                      errors={formik.errors}
                    />
                  );
                case "image":
                  return (
                    <FileField
                      value={value}
                      onChange={(e: any) => handleFile(field.name, e)}
                      onDelete={() => deleteFile(field.name)}
                    />
                  );

                case "checkbox":
                  return (
                    <CheckboxField
                      value={value}
                      onChange={(e: any) =>
                        updateItem(field.name, e.target.checked)
                      }
                    />
                  );
                case "radio":
                  return (
                    <RadioField
                      options={field.options?.values}
                      value={value}
                      onChange={(e: any) => handleRadio(field.name, e)}
                      name={field.name}
                      errors={formik.errors}
                    />
                  );
                case "textarea":
                  return (
                    <>
                      <BasicField
                        type={field.type}
                        value={value}
                        as="textarea"
                        rows="3"
                        onChange={(e: any) =>
                          updateItem(field.name, e.target.value)
                        }
                        name={field.name}
                        errors={formik.errors}
                      />
                    </>
                  );
                case "select-multiple":
                case "select":
                  let possibleValues =
                    (field.options?.values &&
                      field.options?.values.map((option: any) => {
                        return {
                          label: option[labelField],
                          value: option[identifierField],
                        };
                      })) ||
                    [];

                  return (
                    <SelectField
                      name={field.name}
                      options={possibleValues}
                      isMulti={
                        field.options?.searchIsMulti ||
                          field["type"] === "select-multiple"
                          ? true
                          : false
                      }
                      onChange={(values: any) =>
                        handleSelect(field.name, values)
                      }
                      value={value}
                      errors={formik.errors}
                    />
                  );
                case "creatable":  // works only with string options
                  let creatableOptions =
                    (field.options?.values &&
                      field.options?.values.map((option: any) => {
                        return {
                          label: option[labelField],
                          value: option[identifierField],
                        };
                      })) ||
                    [];

                  return (<Creatable
                    name={field.name}
                    options={creatableOptions}
                    onChange={(values: any) => {
                      handleSelect(field.name, values);
                    }
                    }
                    value={value ? { label: value, value: value } : null}
                  // errors={formik.errors}
                  />);
                case "autocomplete":
                  if (value) {
                    if (typeof value === "object" && value instanceof Array) {
                      value =
                        value &&
                        value.length > 0 &&
                        value.map((val: any) => {
                          return {
                            label: val[labelField],
                            value: val[identifierField],
                          };
                        });
                    } else {
                      value = {
                        label: value[labelField],
                        value: value[identifierField],
                      };
                    }
                  }

                  // If autocomplete is dependent of other value, we create "getter" to get theses other values
                  if (
                    field.options?.relatedTo?.field &&
                    formik.values[field.options.relatedTo.field]
                  ) {
                    field.options.relatedTo.getValues = () => {
                      const identifier = field.options?.identifier
                        ? field.options?.identifier
                        : "id";

                      if (
                        Array.isArray(
                          formik.values[field.options?.relatedTo.field]
                        )
                      ) {
                        return (
                          formik.values[field.options?.relatedTo.field] &&
                          formik.values[field.options?.relatedTo.field].map(
                            (value: any) => value[identifier]
                          )
                        );
                      } else {
                        return [
                          formik.values[field.options?.relatedTo.field][
                          identifier
                          ],
                        ];
                      }

                    };
                  }

                  return (
                    <AutocompleteField
                      field={field}
                      onSelect={(values: any) =>
                        handleAutocompleteSelect(field.name, values)
                      }
                      value={value}
                    />
                  );
                case "readonly":
                  return value;
                case "richtext":
                  return (
                    <>
                      <div
                        className={
                          formik.errors[field.name]
                            ? "select-container is-invalid"
                            : "select-container"
                        }
                      >
                        <JoditEditor
                          value={value}
                          config={editorConfig}
                          onChange={(content: any) =>
                            updateItem(field.name, content)
                          }
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors[field.name]}
                      </Form.Control.Feedback>
                    </>
                  );
                case "timewindows":
                  return (
                    // <TimeWindows
                    //   value={value}
                    //   onChange={(value, isValid) => {
                    //     if (isValid) {
                    //       updateItem(field.name, value);
                    //       formik.setErrors([field.name], null);
                    //     } else {
                    //       formik.setErrors(
                    //         [field.name],
                    //         "TimeWindows invalid"
                    //       );
                    //     }
                    //   }}
                    // />
                    <div>TO do time windows</div>
                  );
              }
            })()}
          </Col>
        </Form.Group>
      </div>
    ) : (
      <React.Fragment key={field.name} />
    );
  }

  return (
    item && (
      <Formik
        initialValues={item}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values);
        }}
        validationSchema={Yup.object().shape(validationSchema)}
      >
        {({ values, handleSubmit, setFieldValue, errors, setErrors }) => {
          formik = {
            values,
            setFieldValue,
            errors,
            setErrors,
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                {fields.map((field: Field) => {
                  return renderFormField(field);
                })}
              </Row>
              <Row>
                <Col className="text-center">
                  <Button className="btn-space" variant="secondary" onClick={handleClickOpen}>
                    {[<FontAwesomeIcon icon={faTimes} size="lg" />, " Annuler"]}
                  </Button>

                  <Button
                    type="submit"
                    disabled={
                      formik.errors &&
                      Object.keys(formik.errors).length > 0
                    }
                    className="ms-1"
                  >
                    {item.id
                      ? [
                        <FontAwesomeIcon key="edit" icon={faEdit} />,
                        " Modifier",
                      ]
                      : [
                        <FontAwesomeIcon key="add" icon={faPlus} />,
                        " Ajouter",
                      ]}
                  </Button>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Souhaitez-vous annuler vos saisies ?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleClose}>Annuler</Button>
                      <Button onClick={() => window.location.reload()} autoFocus>
                        Valider
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    )
  );
}



export default ItemForm;










const BasicField = ({ name, type, value, onChange, errors, ...props }: any) => {
  return (
    <>
      <Form.Control
        type={type}
        onChange={(e: any) => onChange(e)}
        value={value || ""}
        isInvalid={errors && typeof errors[name] !== "undefined"}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors && errors[name]}
      </Form.Control.Feedback>
    </>
  );
};

const CheckboxField = ({ value, onChange }: any) => {
  return (
    <Form.Check
      type="checkbox"
      label=""
      checked={value}
      onChange={(e: any) => onChange(e)}
    />
  );
};

const FileField = ({ value, onDelete, onChange }: any) => {
  return (
    <span>
      {value && (
        <img
          src={value}
          width="200px"
          onClick={() => onDelete()}
          alt="User avatar"
        />
      )}
      <div className="fileInput">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faUpload} /> Choisir un fichier
        </Button>
        <Form.Control type="file" accept="image/png, image/jpeg" onChange={(e: any) => onChange(e)} />
      </div>
    </span>
  );
};

const RadioField = ({ options, name, value, onChange, errors }: any) => {
  return (
    <>
      <div className={errors[name] ? "is-invalid" : ""}>
        {options &&
          options.map((optionValue: any, optionValueIdx: number) => {
            return (
              <Form.Check
                key={optionValueIdx}
                name={name}
                id={name + optionValue.name}
                value={optionValue.name}
                type="radio"
                label={optionValue.name}
                checked={optionValue.value === value}
                inline
                onChange={(e: any) => onChange(e)}
              />
            );
          })}
      </div>
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </>
  );
};

const SelectField = ({
  name,
  options,
  value,
  onChange,
  errors,
  ...props
}: any) => {
  let val = [];

  if (Array.isArray(value)) {
    value.forEach((item: any) => {
      let findValue = options.find(
        (possibleValue: any) => possibleValue.value === item
      );
      if (findValue) {
        val.push(findValue);
      }
    });
  } else {
    let findValue = options.find(
      (possibleValue: any) => possibleValue.value === value
    );
    if (findValue) {
      val.push(findValue);
    }
  }

  return (
    <>
      <div
        className={
          errors[name] ? "select-container is-invalid" : "select-container"
        }
      >
        <Select
          {...props}
          options={options}
          onChange={(values) => onChange(values)}
          value={val}
          loadingMessage={() => "Chargement en cours..."}
          noOptionsMessage={() => "Aucune valeur à sélectionner"}
          placeholder=""
        />
      </div>
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </>
  );
};
