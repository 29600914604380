import React from "react";
import { Breadcrumbs as MatBreadcrumbs, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import "./Breadcrumbs.scss";
import { RouteObjectProps } from "../../../routes";

type BreadcrumbsProps = {
    routes: RouteObjectProps[]
}

const Breadcrumbs = ({ routes = [] }: BreadcrumbsProps) => {
    const breadcrumbs = useBreadcrumbs(routes);

    return (
        <MatBreadcrumbs className="breadcrumbs" separator="›" aria-label="breadcrumb">
            {breadcrumbs.map(({ match }, index) => {
                if (match !== undefined && match !== null) {
                    const currentMatch: any = Object.assign({}, match);
                    const routeLabel = currentMatch?.route?.label;
                    const routeElement = currentMatch?.route?.element;
                    const routeKey = index + match.pathname;

                    if (routeLabel) {
                        if (index + 1 === breadcrumbs.length) {
                            return <Link key={routeKey} >{routeLabel}</Link>
                        }

                        if (routeElement) {
                            return <Link underline="hover" color="text.primary" key={routeKey} to={match.pathname} component={RouterLink}>
                                {routeLabel}
                            </Link>
                        } else {
                            return <Link key={routeKey} >{routeLabel}</Link>
                        }
                    }
                }
                return null;
            })}
        </MatBreadcrumbs>
    );
};

export default Breadcrumbs;
