import React from "react";
import Item from "../Common/Item/Item";
import moment from "moment";
import { Button, Grid, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import { notificationService } from "../Common/Layout/Notification";

export type Application = {
    "name": string,
    "technical_code": string,
    "version": string,
    "session_duration": number,
    "licences_count": number,
    "sessions_count": number,
    "is_active": boolean,
    "id": number,
    "created_at": number,
    "updated_at": number;
}

const Applications = (params: any) => {
    const endpoint = "/api/administration/applications/";
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const formFields = [
        {
            name: "name",
            type: "text",
            label: "Nom"
        },
        {
            name: "technical_code",
            type: "text",
            label: "Code technique"
        },
        {
            name: "version",
            type: "text",
            label: "Version diffusée"
        },
        {
            name: "session_duration",
            type: "number",
            label: "Durée session (en sec)"
        },
        {
            name: "is_active",
            type: "radio",
            label: "Etat",
            options: {
                values: [
                    { value: true, name: "Actif" },
                    { value: false, name: "Inactif" },
                ],
            },
            defaultValue: true,
        },
        {
            name: "updated_at",
            type: "readonly",
            label: "Dernière modification",
            transform: (value: any) => {
                let date =
                    value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
                return date || "";
            },
        },
    ];
    const listFields = [
        { name: "name", label: "Application", orderable: true },
        {
            name: "version",
            label: "Version diffusée",
            options: { width: "180px" },
            orderable: true,
        },
        {
            name: "created_at",
            label: "Date de création",
            options: { width: "180px" },
            orderable: true,
            transform: (value: any) => {
                return (
                    (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
        },
        { name: "licences_count", label: "Nb de licence", orderable: true, options: { width: "160px" }, },
        {
            name: "is_active",
            label: "Actif",
            className: "text-center",
            options: { width: "100px" },
            orderable: true,
        },
        { name: "sessions_count", label: "Nb de sessions", orderable: true, options: { width: "170px" }, },

    ];
    const searchFields = [
        {
            name: "name",
            label: "Application",
            options: {
                identifier: "name",
                source: endpoint,
                label: "name",
                searchIsMulti: false,
            },
        }
    ];
    const actions = [
        {
            name: "edit",
            render: (application: any) => {
                return <Link to={`/applications/edit/${application.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
            }
        },
        {
            name: "delete-application",
            render: (row: any) => {
                return <IconButton size="small" onClick={() => {
                    confirmDialog({
                        title: "Supprimer l'application",
                        content: "Êtes-vous sûr de vouloir supprimer cette application?",
                        onValidate: () => {
                            axios.delete(`${endpoint}${row.id}/`).then(
                                (response: any) => {
                                    notificationService.show({
                                        title: "Suppression effectuée avec succès",
                                        message: "L'application a été supprimée avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                (error) => {
                                    notificationService.show({
                                        title: "Erreur",
                                        message: "Une erreur est survenue lors de la suppression",
                                        type: "danger",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}><DeleteIcon /></IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            {params.action === "list" && (
                <>
                    <Grid
                        justifyContent="space-between"
                        container
                    >
                        <Grid item>
                            <StyledTitle>Liste des applications</StyledTitle>
                        </Grid>
                        <Grid item>
                            <Link to="/applications/add">
                                <Button variant="contained" sx={{ mt: 3 }}>
                                    <AddCircleIcon sx={{ mr: 1 }} />  Ajouter une application
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <ListPage
                        endpoint={endpoint}
                        fields={listFields}
                        actions={actions}
                        searchFields={searchFields}
                    />
                </>
            )}
            {params.action === "add" && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Ajouter une application"
                />
            )}
            {params.action === "edit" && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Modifier l'application"
                />
            )}
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
}

export default Applications;
