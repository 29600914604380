import React, { useCallback, useEffect, useState } from "react";
import { AppPaper, StyledTitle, EditableTable, InputField, SelectField } from '@applications-terrains/birdz-react-library';
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { notificationService } from "../Common/Layout/Notification";
import * as Yup from 'yup'
import { Card, Grid } from "@mui/material";
import { Application } from "../Applications/Applications";

type LicenceDetailParams = {
    id?: string;
}

type OptionsUsers = Array<{ value: number, label: string }>;

const LicenceDetail = () => {
    const { id } = useParams<LicenceDetailParams>();
    const [application, setApplication] = useState<Application>();
    const [endpoint, setEndpoint] = useState<string>();
    const [users, setUsers] = useState<OptionsUsers>();
    const [listVisibility, setListVisibility] = useState<boolean>(true);
    const refreshListing = () => {
        setListVisibility(false);
        setTimeout(() => {
            setListVisibility(true);
        });
    };

    const loadApplication = useCallback(async () => {
        const response = await axios.get(`/api/administration/applications/${id}/`);
        setApplication(response.data);
    }, [id]);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('/api/administration/users/?size=10000');
            const formatedUsers = response?.data?.results.map((user: any) => {
                return {
                    value: user.id,
                    label: user.name
                }
            });
            setUsers(formatedUsers)
        };
        fetchUsers();
        loadApplication();
        setEndpoint(`/api/administration/licences/?app=${id}&size=10000`);
    }, [id, loadApplication]);

    return (
        <AppPaper>
            <StyledTitle>Détail licence par application</StyledTitle>

            {application &&
                <Card sx={{ p: 2, mb: 2 }}>
                    <h3>Informations</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>Nom application</Grid>
                        <Grid item xs={9}>{application?.name}</Grid>
                        <Grid item xs={3}>Dernière version</Grid>
                        <Grid item xs={9}>{application?.version}</Grid>
                        <Grid item xs={3}>Date de dernière version</Grid>
                        <Grid item xs={9}>{moment.unix(application?.updated_at).format('DD/MM/YYYY à hh:mm')}</Grid>
                    </Grid>
                </Card>
            }

            {listVisibility &&
                <EditableTable
                    endpoint={endpoint}
                    fields={[
                        {
                            name: "user",
                            label: "Utilisateur",
                            renderFormAdd: (currentRow: any, results: any[]) => <SelectField name="user" options={users} label="Utilisateur" selectedItems={results.map((result: any) => result.user)} />,
                            renderFormEdit: (currentRow: any) => {
                                const user = users?.find(user => user.value === currentRow.user);
                                return user?.label;
                            },
                            transform: (userId: number) => {
                                const user = users?.find(user => user.value === userId);
                                return user?.label;
                            }
                        },
                        {
                            name: 'number',
                            label: "Nb de licences",
                            renderForm: () => <InputField name="number" type="number" />,
                        },
                        {
                            name: 'session_count',
                            label: "Sessions en cours",
                        },
                        {
                            name: 'created_at',
                            label: "Date de création",
                            transform: (date: number) => { return moment.unix(date).format("DD/MM/YYYY à HH:mm") },
                        },
                        {
                            name: 'last_connection',
                            label: "Date dernière connexion",
                            transform: (date: string) => { return moment(date).format("DD/MM/YYYY à HH:mm") },
                        },
                    ]}
                    onSave={(values: any) => {
                        let payload = values;
                        if (payload?.id) {
                            axios.put(`/api/administration/licences/${values.id}/`, payload).then(() => {
                                notificationService.show({ type: 'success', title: 'Licence modifiée', message: 'La licence a été modifiée avec succès' })
                            }, (error) => {
                                notificationService.show({ type: 'danger', title: 'Erreur modification', message: 'Une erreur est survenue lors de la modification de la licence' })
                            }).finally(() => refreshListing());
                        }
                        else {
                            payload.app = id ? +id : id;
                            axios.post('/api/administration/licences/', payload).then(() => {
                                notificationService.show({ type: 'success', title: 'Licence créée', message: 'La licence a été créée avec succès' })
                            }, (error) => {
                                notificationService.show({ type: 'danger', title: 'Erreur création', message: 'Une erreur est survenue lors de la création de la licence' })
                            }).finally(() => refreshListing());;
                        }

                    }}
                    validationSchema={
                        Yup.object().shape({
                            user: Yup.number().required('Champ obligatoire'),
                            number: Yup.number().required(
                                "Vous devez choisir un nombre de licence"
                            ),
                        })}
                />}
        </AppPaper>
    );
}

export default LicenceDetail;
