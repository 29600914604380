import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "./Auth";

const PrivateOutlet = () => {
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        async function getUserInfo() {
            await auth.getUserInfo();
            setIsReady(true)
        }

        if (!auth.initialized) {
            getUserInfo()
        } else {
            setIsReady(true)
        }
    }, []);
    return isReady ? auth.isAuthenticated ? <Outlet /> : <Navigate to="/auth" /> : <></>;
}

export default PrivateOutlet;