import React from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import packageJson from "../../../package.json";

import "./Sidebar.scss";
import { auth } from "../Common/Auth/Auth";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DvrIcon from '@mui/icons-material/Dvr';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { IconButton } from "@mui/material";

type ApiVersion = {
  version: string;
  environment: string;
  last_commit: string;
}

const Sidebar = ({ ...props }) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [menuIsExpanded, setMenuIsExpanded] = useState<boolean>(true);
  const [elementHovered, setElementHovered] = useState<string>();
  const { staticContext, ...rest } = props;
  const [apiVersion, setApiVersion] = useState<ApiVersion>();
  const [showUiVersion, setShowUiVersion] = useState<boolean>();
  const appVersion = packageJson?.version;

  useEffect(() => {
    // Load expanded menu pref stored in localstorage
    let menuIsExpanded = localStorage.getItem("menuIsExpanded") || "true";
    menuIsExpanded = JSON.parse(menuIsExpanded);
    if (typeof menuIsExpanded === "boolean") {
      setMenuIsExpanded(menuIsExpanded)
      setExpandedCssClasses(menuIsExpanded);
    }

    axios.get("/api/version/").then((response) => {
      setApiVersion(response?.data);
    });
  }, [])



  const handleToggleMenu = (activeKeySelected: string) => {
    let newActiveKey = activeKeySelected === activeKey ? "" : activeKeySelected;
    setActiveKey(newActiveKey);
    localStorage.setItem("activeKey", newActiveKey);
  };

  const toggleBurger = () => {
    let newMenuIsExpanded = !menuIsExpanded;
    setMenuIsExpanded(newMenuIsExpanded);
    localStorage.setItem("menuIsExpanded", newMenuIsExpanded + "");
    setExpandedCssClasses(newMenuIsExpanded);
  }

  const handleHoverOff = () => {
    setElementHovered('');
  }

  const handleHoverOn = (activeKey: string) => {
    setElementHovered(activeKey);

    if (!menuIsExpanded) {
      setActiveKey(activeKey);
    }
  }

  const setExpandedCssClasses = (menuIsExpanded: boolean) => {
    if (menuIsExpanded) {
      document.body.classList.add("sidebar-expanded");
      document.body.classList.remove("sidebar-collapsed");
    } else {
      document.body.classList.remove("sidebar-expanded");
      document.body.classList.add("sidebar-collapsed");
    }
  }

  type MenuItemProps = {
    menuKey: string;
    icon: any;
    name: string;
    children?: JSX.Element;
    link?: string
  }
  const MenuItem = ({ menuKey, icon, name, children, link }: MenuItemProps) => {
    const MenuHeader = () => {
      return <>
        <div className="icon">{icon}</div>
        <span>{name}</span>
        {
          children && <span className="arrow-right">
            {activeKey === menuKey ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </span>
        }</>;
    }
    return <Card
      onClick={() => { handleHoverOn(menuKey); }}
      onMouseEnter={() => { handleHoverOn(menuKey); }}
      onMouseLeave={() => { handleHoverOff(); }}
    >
      <Card.Header onClick={() => { !link && handleToggleMenu(menuKey) }}>
        {link ? <Link to={link}><MenuHeader /></Link> : <MenuHeader />}
      </Card.Header>
      {
        children &&
        <Accordion.Collapse eventKey={menuKey}>
          <Card.Body className={`${elementHovered === menuKey ? "hover" : ""}`}>
            {children ? children : null}
          </Card.Body>
        </Accordion.Collapse>
      }
    </Card >
  }

  return (
    <div className={"sidebar " + apiVersion?.environment} {...rest}>
      <div className="logo">
        <Link to="/">
          <img
            src={
              process.env.PUBLIC_URL +
              "/licence-logo.png"
            }
            alt="B-Licence"
          />
        </Link>
      </div>
      <div id="burger" onClick={() => toggleBurger()}>
        <IconButton aria-label="Menu">
          <MenuIcon />
        </IconButton>
      </div>
      <div className="welcome">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="auto">
              <div className="icon">
                {auth.user?.photo ? (
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: 'url("' + auth.user.photo + '"',
                    }}
                  />
                ) : <AccountCircleIcon />}
              </div>
            </Col>
            <Col>
              <p>
                <Trans>Bienvenue</Trans>
              </p>
              <p>{auth.user ? auth.user.name : ""}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sidebar-wrapper">
        <div className="sidebar-scroll">
          <Nav className="flex-column">
            <Accordion className="menu" activeKey={activeKey}>
              <MenuItem menuKey={"apps"} name="Applications" icon={<DvrIcon />} link={"/applications"} />
              <MenuItem menuKey="licences" name="Licences" icon={<VpnKeyIcon />} link={"/licences"} />
              <MenuItem menuKey="sessions" name="Sessions" icon={<ManageHistoryIcon />} link={"/sessions"} />
              <MenuItem menuKey="admin" name="Administration" icon={<SettingsIcon />}>
                <>
                  <Link to="/administration/users">Utilisateurs</Link>
                  <Link to="/administration/groups">Groupes</Link>
                  <Link to="/administration/sections">Sections</Link>
                  <Link to="/administration/messages">Messages</Link>
                  <Link to="/administration/logs">Logs</Link>
                  <Link to="/administration/companies">Sociétés</Link>
                </>
              </MenuItem>
            </Accordion>
          </Nav>
        </div>
      </div >
      <span className="logout">
        <Button onClick={auth.logout}><PowerSettingsNewIcon /></Button>
      </span>

      <span className="version">
        <div onClick={() => { setShowUiVersion(!showUiVersion) }}>
          <span title={`Dernier commit: ${apiVersion?.last_commit || '-'}`}>Version: {apiVersion?.version}</span>
          {" "}
          <span title="UI version" style={{ visibility: showUiVersion ? 'visible' : 'hidden' }}> / UI: {appVersion}</span>
        </div>
      </span>
    </div >
  );
}


export default Sidebar;
