import React from "react";
import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library';
import HomeMessages from "./HomeMessages/HomeMessages";

const Home = () => {
  return (
    <AppPaper>
      <StyledTitle>Bienvenue sur l'application Gestion des licences</StyledTitle>

        <HomeMessages />
      </AppPaper>
  );
}

export default Home;


