import React from "react";
import { Toast } from "react-bootstrap";

export type Options = {
  type:
    | "primary"
    | "success"
    | "secondary"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | undefined;
  title: string;
  message?: string;
  time?: number;
};

interface Props {}

interface State {
  show: boolean;
  options: Options | null;
}

export class Notification extends React.Component<Props, State> {
  public state: State = {
    show: false,
    options: null,
  };

  public show(options: Options) {
    this.setState({ show: true, options });
  }

  public hide() {
    this.setState({ show: false });
  }

  public render() {
    return this.state.show ? (
      <Toast
        onClose={() => this.hide()}
        show={this.state.show}
        delay={this.state.options?.time || 4000}
        autohide
        style={{
          position: "fixed",
          minHeight: "100px",
          minWidth: "200px",
          top: "10px",
          right: "10px",
          display: this.state.show ? "block" : "none",
          zIndex: 1,
        }}
        className={"toast-" + this.state.options?.type}
      >
        <Toast.Header>
          <strong>{this.state.options?.title}</strong>
        </Toast.Header>
        <Toast.Body
          dangerouslySetInnerHTML={{
            __html: this.state.options?.message || "",
          }}
        ></Toast.Body>
      </Toast>
    ) : (
      <></>
    );
  }
}

class NotificationService {
  public instance: any;

  public show = (options: Options) => this.instance.show(options);
}

export let notificationService = new NotificationService();

// export default Confirm;
