import React from "react";
import Item from "../../Common/Item/Item";
import moment from "moment";
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { notificationService } from "../../Common/Layout/Notification";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import { Button, Grid, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Sections = (params: any) => {
    const endpoint = "/api/administration/sections/";
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    const formFields = [
        {
            name: "code",
            type: "text",
            label: "Nom"
        },
        {
            name: "description",
            type: "text",
            label: "Description"
        },
        {
            name: "is_active",
            type: "radio",
            label: "Etat",
            options: {
                values: [
                    { value: true, name: "Actif" },
                    { value: false, name: "Inactif" },
                ],
            },
            defaultValue: true,
            // permissions: ["IS_ACTIVE"],
        },
        {
            name: "updated_at",
            type: "readonly",
            label: "Dernière modification",
            transform: (value: any) => {
                let date =
                    value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
                return date || "";
            },
        },
    ];
    const listFields = [
        { name: "description", label: "Description", orderable: true },
        {
            name: "code",
            label: "Code section",
            options: { width: "140px" },
            orderable: true,
        },
        {
            name: "is_active",
            label: "Actif",
            className: "text-center",
            options: { width: "100px" },
            orderable: true,
        },
        {
            name: "updated_at",
            label: "Date de modification",
            options: { width: "220px" },
            orderable: true,
            transform: (value: any) => {
                return (
                    (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
        },
    ];
    const searchFields = [
        {
            name: "description",
            label: "Description",
            options: {
                identifier: "description",
                source: endpoint,
                label: "description",
                searchIsMulti: false,
            },
        },
        {
            name: "code",
            label: "Code Section",
            options: {
                identifier: "code",
                source: endpoint,
                label: "code",
                searchIsMulti: false,
            },
        },
        {
            name: "group",
            label: "Groupe",
            options: {
                source: "/api/administration/groups/",
                label: "name",
                searchIsMulti: false,
                dataKey: 'groups'
            },
        },
    ];
    const actions = [
        {
            name: "edit",
            render: (row: any) => {
                return <Link to={`/administration/sections/edit/${row.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
            }
        },
        {
            name: "delete-section",
            render: (row: any) => {
                return <IconButton size="small" onClick={() => {
                    confirmDialog({
                        title: "Supprimer la section",
                        content: "Êtes-vous sûr de vouloir supprimer cette section?",
                        onValidate: () => {
                            axios.delete(`${endpoint}${row.id}/`).then(
                                (response: any) => {
                                    notificationService.show({
                                        title: "Suppression effectuée avec succès",
                                        message: "La section a été supprimée avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                (error) => {
                                    notificationService.show({
                                        title: "Erreur",
                                        message: "Une erreur est survenue lors de la suppression",
                                        type: "danger",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}><DeleteIcon /></IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            {params.action === "list" && (
                <>
                    <>
                        <Grid
                            justifyContent="space-between"
                            container
                        >
                            <Grid item>
                                <StyledTitle>Liste des sections</StyledTitle>
                            </Grid>
                            <Grid item>
                                <Link to="/administration/sections/create">
                                    <Button variant="contained" sx={{ mt: 3 }}>
                                        <AddCircleIcon sx={{ mr: 1 }} />  Ajouter une section
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>

                        <ListPage
                            endpoint={endpoint}
                            fields={listFields}
                            actions={actions}
                            searchFields={searchFields}
                        />
                    </>
                </>
            )}
            {params.action === "add" && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Ajouter une section"
                />
            )}
            {params.action === "edit" && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Modifier la section"
                />
            )}
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
}

export default Sections;
