import { blue } from "@mui/material/colors";
import { createTheme, PaletteColor } from "@mui/material/styles";

export const customBlue: PaletteColor = {
  light: "",
  main: "#253053",
  dark: "",
  contrastText: "white",
};

export const appTheme = createTheme({
  palette: {
    primary: customBlue,
    secondary: blue,
  },
  typography: {
    h2: {
      fontSize: "1.2rem !important",
      marginTop: 4,
      marginBottom: 4,
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          verticalAlign: "middle",
        },
      },
    },
  },
});
