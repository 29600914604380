import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./HomeMessages.scss";
import { Message } from '../../types'


const HomeMessages = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    useEffect(() => {
        let endpoint = '/api/administration/messages/?destination=Back-office';

        axios.get(endpoint).then((response: any) => {
            let data = response.data;
            if (data.results) {
                data.results.map((result: any) => {
                    if (!result.id && result.sysid) {
                        result.id = result.sysid;
                    }
                    return result;
                });

                setMessages(data.results);
            }
        });
    }, [])

    return (
        <>
            {messages.map((message) => (
                <Card key={message.id} sx={{ mb: 2 }} variant="outlined">
                    <CardHeader title={message.title} subheader={moment.unix(message.created_at).format('DD/MM/YYYY')} />
                    <CardContent>
                        <Typography dangerouslySetInnerHTML={{ __html: message.content }} />
                    </CardContent>
                </Card>
            ))}
        </>
    );
};

export default HomeMessages;