import React, { useEffect, useState } from 'react';
import './App.scss';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import routes from './routes';
import dataService from './components/Common/Services/dataService';
import * as _ from "lodash";
import { auth } from './components/Common/Auth/Auth';
import { ThemeProvider } from '@mui/material';
import { appTheme } from './styles/theme';

const App = () => {
  const [isReady, setIsReady] = useState<boolean>(false)
  const userPermissions = auth?.user?.permissions || [];

  useEffect(() => {
    async function loadDataService() {
      try {
        const permissions = auth?.user?.permissions;
        await dataService.loadData(permissions);
        setIsReady(true);
      } catch (e) {

      }
    }

    async function getUserInfo() {
      await auth.getUserInfo();
      await loadDataService();
    }

    if (!auth.initialized) {
      getUserInfo()
    } else {
      loadDataService()
    }




  }, [])

  const displayRoutes = (routes: any) => {
    return routes.map((route: any, index: number) => {
      if (route.permissions && auth.isAuthenticated) {
        const permissionsGranted = _.intersection(userPermissions, route.permissions).length === route.permissions.length;
        console.log(`[${permissionsGranted ? 'OK' : 'KO'}] Permissions needed for route "${route?.path}": [${route.permissions.join(', ')}], user permissions are: [${userPermissions.join(', ')}]`)
        if (!permissionsGranted) {
          return null;
        }
      }

      if (route.path) {
        return <Route
          key={route.path + index}
          path={route.path}
          element={route.element}
          children={route.children && route.children.length > 0 ? displayRoutes(route.children) : null}
        />
      } else {
        return <Route index element={route.element} key={`route-index-${index}`} />
      }
    })
  }

  return (
    <ThemeProvider theme={appTheme}>
      {isReady &&
        <BrowserRouter>
          <Routes>
            {/* {
            _.map(route => {
                return <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                    children={route.children?.map((child: any) => {
                      return <Route
                        key={child.path}
                        path={child.path}
                        element={child.element}
                      />
                    })}
                />
            }, routes)
          } */}
            {displayRoutes(routes)}
            {/* <Route path="/" element={<Outlet />}>
              <Route index element={<>home <Outlet /></>} />
              <Route path="groups" element={<Outlet />}>
                <Route path=":teamId" element={<>b <Outlet /></>} />
                <Route path="new" element={<>c</>} />
                <Route index element={<>d <Outlet /></>} />
              </Route>
            </Route> */}
          </Routes>
        </BrowserRouter>
      }
    </ThemeProvider>
  );
}

export default App;
