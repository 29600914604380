import React from "react";
import { Link } from "react-router-dom";
import Item from "../../Common/Item/Item";
import { Field } from "../../Common/Config/FieldsInterface";
import moment from "moment";
import * as Yup from "yup";
import dataService from "../../Common/Services/dataService";
import { Button, Grid, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppPaper, StyledTitle, ListPage, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { notificationService } from "../../Common/Layout/Notification";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

const Messages = (params: any) => {
  const endpoint = "/api/administration/messages/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  const formFields: Field[] = [
    {
      name: "title",
      type: "text",
      label: "Titre message",
      validation: Yup.string().required(
        "Vous devez renseigner le titre du message"
      ),
    },
    {
      name: "type",
      type: "select",
      label: "Type message",
      options: {
        values: dataService.getData("messageTypes"),
        identifier: "label",
        label: "label",
      },
      validation: Yup.mixed().required(
        "Vous devez renseigner le type du message"
      ),
    },
    {
      name: "destination",
      type: "select-multiple",
      label: "Destination",
      options: {
        values: [
          { id: 'Back-office', name: "Back-office" }
        ]
      }
    },
    {
      name: "content",
      type: "richtext",
      label: "Contenu message",
      validation: Yup.string().required(
        "Vous devez renseigner le contenu du message"
      ),
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, name: "Actif" },
          { value: false, name: "Inactif" },
        ],
      },
      defaultValue: true,
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  const listFields = [
    {
      name: "title",
      label: "Titre Message",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: 'destination',
      label: 'Cible',
      transform: (value: string[]) => {
        return value?.join(', ');
      }
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
      orderable: true,
      options: { width: "220px" },
    },
  ];

  const searchFields = [
    {
      name: "title",
      label: "Titre message",
      options: {
        identifier: "title",
        source: endpoint,
        label: "title",
        searchIsMulti: false,
      },
    },

    {
      name: "destination",
      label: "Destination",
      type: "select",
      options: {
        values: [
          { value: "Back-office", label: "Back-office" }
        ],
      },
    },
  ];

  const actions = [
    {
      name: "edit",
      render: (row: any) => {
        return <Link to={`/administration/messages/edit/${row.id}`}><IconButton size="small"><EditIcon /></IconButton></Link>
      }
    },
    {
      name: "delete-message",
      render: (row: any) => {
        return <IconButton size="small" onClick={() => {
          confirmDialog({
            title: "Supprimer le message",
            content: "Êtes-vous sûr de vouloir supprimer ce message?",
            onValidate: () => {
              axios.delete(`${endpoint}${row.id}/`).then(
                (response: any) => {
                  notificationService.show({
                    title: "Suppression effectuée avec succès",
                    message: "Le message a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                (error) => {
                  notificationService.show({
                    title: "Erreur",
                    message: "Une erreur est survenue lors de la suppression",
                    type: "danger",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><DeleteIcon /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid
            justifyContent="space-between"
            container
          >
            <Grid item>
              <StyledTitle>Liste des messages</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/administration/messages/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} />  Ajouter un message
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un message"
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le message"
        />
      )}
      <BirdzDialog options={dialogOptions} />
    </AppPaper>
  );
};

export default Messages;